<template>
  <div class="heade_wrap">
    <header class="header">
      <button class="lnk btn_prev" @click="previous">이전으로</button>
      <a href="javascript:void(0);"  class="event_link"
          @click="activeMenu(1)"
          v-show="activeMenuIndex === 0">EVENT + HALL</a>
      <span class="mbtns">
        <button class="lnk btn_close">닫기</button>
        <button class="lnk btn_menu"
          @click="gnbOpen()"><span>전체 메뉴</span></button>
      </span>
      <h1>
        <router-link class="btn_home" @click.native="activeMenu(0)" to="/">
          <span class="ico_logo">DREAMPLUS</span>
        </router-link>
        <strong class="title_h1">{{ $route.meta.title }}</strong>
      </h1>
    </header>
    <div class="gnb_wrap" :class="{'on': gnbHover}">
        <div class="bg" @click="gnbClose()"></div>
        <nav class="gnb_box">
          <ul class="nav_menu">
            <li v-for="(menu, index) in menus[activeMenuIndex]" :key="index" role="presentation" class="nav_item">
              <router-link
                v-if="menu.subMenus === undefined"
                @click.native="onTopMenuMouseOver(index)"
                :to="menu.to"
                :data-index="index"
                role="tab" class="nav_tab"
                :aria-selected="index === matchedTopMenuIndex"
              >
                {{menu.title}}
              </router-link>
              <a v-else
                href="#" class="nav_tab sub" role="tab"
                @click="subExpended = !subExpended"
                :data-index="index"
                :class="{'router-link-active': index === matchedTopMenuIndex}"
                :aria-expanded="subExpended"
              >
                {{menu.title}}
              </a>
              <ul class="submenu">
                <li v-for="(smenu, sindex) in menu.subMenus" :key="sindex">
                  <router-link
                    @click.native="onTopMenuMouseOver(index)"
                    class="link"
                    :to="smenu.to"
                  >
                    {{smenu.title}}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <div class="aside_menu">
            <a href="javascript:void(0);"  class="event_link"
              @click="activeMenu(1)"
              v-show="activeMenuIndex === 0">EVENT + HALL</a>
            <a href="javascript:void(0);"  class="event_link"
              @click="activeMenu(0)"
              v-show="activeMenuIndex === 1">DREAMPLUS</a>
            <a href="javascript:void(0);"  class="event_link"
              @click="ganGnamGo"
              v-show="activeMenuIndex === 1">DREAMPLUS GANGNAM</a>
            <!-- <a href="javascript:void(0);"  class="event_link"
              @click="six3Go"
              v-show="activeMenuIndex === 1">DREAMPLUS 63</a> -->
          </div>
          <div class="util_menu">
            <router-link
              v-if="$store.state.userInfo"
              to="/signout"
              tag="a" class="btn_logout"
              @click.native="gnbClose()"
            >Logout</router-link>
            <router-link
              v-else
              to="/user/signin"
              tag="a" class="btn_logout"
              @click.native="gnbClose()"
            >Login</router-link>
            <div class="lang_area">
              <router-link
                to="/ko"
                :aria-selected="$i18n.locale === 'ko'"
                role="button" class="btn_lang"
              >KR</router-link>
              <router-link
                to="/en"
                :aria-selected="$i18n.locale === 'en'"
                role="button"
                class="btn_lang"
              >EN</router-link>
            </div>
          </div>
          <button type="button" class="btn_menu_close" @click="gnbClose()">메뉴닫기</button>
        </nav>
    </div>
  </div>
</template>

<script>
/**
 * GNB
 */
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'NewGlobalNavigationBar',
  mixins: [
    mixinHelperUtils,
  ],
  props: {
    activeMenuIndex: Number,
    pageType: String,
  },
  data() {
    return {
      // activeTopMenuIndex: -1,
      subExpended: false,
      pagestep: 0,
      /**
       * 메뉴
       * @type {Array}
       */
      menus: [
        [
          {
            title: 'DREAMPLUS',
            to: '/dreamplus',
            matchCondition: ['/', /\/dreamplus/],
          },
          {
            title: 'STUDIO',
            to: '/studio',
            matchCondition: [/\/studio/],
          },
          {
            title: 'PROGRAMS',
            to: '/programs',
            matchCondition: [/\/programs/],
          },
          {
            title: 'NEWS',
            to: '/contents',
            matchCondition: [/\/contents/],
          },
          {
            title: 'APPLY',
            to: '/contest',
            matchCondition: [/\/contest/],
          },
        ],
        [
          {
            title: 'EVENT',
            to: {
              path: '/event/list',
            },
            matchCondition: ['/event/list'],
          },
          {
            title: 'EVENT HALL',
            to: {
              path: '/reservation/intro',
            },
            matchCondition: ['/reservation/intro'],
          },
          {
            title: 'RESERVATION',
            to: '/history',
            matchCondition: [/\/my/, /\/history/],
            subMenus: [
              {
                title: 'EVENT',
                to: {
                  path: '/event/my',
                },
              },
              {
                title: 'EVENT HALL',
                to: {
                  path: '/reservation/applyandhistory/history',
                },
              },
            ],
          },
        ],
      ],
      gnbHover: false,
      headerTitle: this.$route.meta.title,
      scrollT: 0,
      documentWidth: document.documentElement.offsetWidth,
      currentIndex: 0,
    };
  },
  methods: {
    activeMenu(index) {
      this.$emit('activeMenu', index);
      this.onTopMenuMouseOver(0);
      this.gnbClose();
    },
    previous() {
      const pageValue = this.$store.getters.getPagestep;
      if (pageValue === 1) {
        if (this.$route.path.includes('applyandhistory/apply') && this.$route.path.includes('event')) {
           this.showConfirm(this.$t('content.event.Event.alertMsg.msg4'), () => {
              this.$router.go(-1);
           });
        } else if (this.$route.path.includes('reservation/applyandhistory/apply')) {
           this.showConfirm(this.$t('content.event.EventReservation.alertMsg.msg5'), () => {
              this.$router.go(-1);
           });
        } else {
          this.$router.go(-1);
        }
      } else if (pageValue >= 6) {
         this.$router.go(-1);
      } else {
        this.$store.commit('setPagestep', pageValue - 1);
      }
    },
    ganGnamGo() {
      window.open(`${process.env.VUE_APP_PORTAL}`);
    },
    six3Go() {
      window.open(`${process.env.VUE_APP_63}`);
    },
    eventGo() {
      window.open('/event');
    },
    gnbOpen() {
      this.scrollT = document.body.scrollTop ? document.body.scrollTop : document.documentElement.scrollTop;
      this.gnbHover = true;
      this.bodyScrollLock(true);
    },
    gnbClose() {
      this.gnbHover = false;
      this.bodyScrollLock(false);
    },
    onTopMenuMouseOver() {
      // 마우스로 탐색중인 메뉴를 active하게 변경 (표시)
      // this.activeTopMenuIndex = index;
      this.gnbClose();
    },
  },
  computed: {
    /**
     * match된 상위메뉴 index (현재 URL에 match)
     */
    matchedTopMenuIndex() {
      const { path } = this.$route;
      const indexVal = this.menus[this.activeMenuIndex].findIndex((menu) => menu.matchCondition.some((c) => {
        switch (typeof c) {
          case 'string':
            return c === path;
          default:
            return c.test(path);
        }
      }));
      return indexVal;
    },
  },
};
</script>
